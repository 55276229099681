html {
  scroll-behavior: smooth;
}

:root {
  --flip-duration: 0.5s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.typed-cursor {
  color: black;
  /* font-family: 'Courier New', Courier, monospace; */
  font-weight: 100;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform var(--flip-duration);
  transform-style: preserve-3d;
  height: 350px;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0));
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0));
  max-width: 240px;
  padding: 1.5vw;
}

.card:hover {
  transform: scale(1.05) perspective(1000px) rotateY(var(--rotate-y, 0));
  transition: transform 0.35s ease-out;
  will-change: transform;
}

.card.flipped {
  --rotate-y: 180deg;
}

.card .front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.card .safari-back {
  position: absolute;
  /* padding: 1rem; */
}

.card .back {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.outlined-white {
  border: 2px solid white !important;
  /* Set outline color to white and adjust thickness */
}

/* Custom styling for when autofill is detected */
#blum-email-textfield:-webkit-autofill {
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
  background-clip: text;
}

.page {
  background-color: #FAFAF1;
  width: 100%;
  /* min-height: 100vh; */
}

.highlight-text {
  color: #DCA6B5;
}

.stroked-text {
  color: transparent;
  -webkit-text-stroke-color: #EEB3C1;
  -webkit-text-stroke-width: 5px;
}

@media (prefers-reduced-motion: no-preference) {}

@media (min-width: 1500px) {
  .page-content {
    display: block;
    margin: auto;
    width: 1500px;
    /* display: flex;
    flex-direction: column; */
    /* align-items: center; */
  }
}

/* For mobile phones  */
@media (min-width: 601px) and (max-width: 1499px) {
  .page-background {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}


.react-infinite-text-reel-items {
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 110%;
}

.react-infinite-text-reel-row {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.react-infinite-text-reel-item {
  position: relative;
  display: inline-block;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-weight: 800;
  color: #EEB3C1;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.react-infinite-text-reel-items .react-infinite-text-reel-item-stroke h1 {
  color: transparent;
  font-weight: 800;
  -webkit-text-stroke: 2px #EEB3C1;
}

/* @media only screen and (max-width: 1200px) {
  .react-infinite-text-reel-item {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .react-infinite-text-reel-item {
    font-size: 37px;
  }
} */