.init_invisible {
    opacity: 0;
    transition: opacity 0.2s 0.2s ease-out;
    will-change: opacity;
}

.init_left {
    opacity: 0;
    transform: translateX(-50%);
    transition: transform 0.2s ease-out,
                opacity 0.2s ease-out;
    will-change: opacity, transform;
}

.init_right {
    opacity: 0;
    transform: translateX(50%);
    transition: transform 0.2s ease-out,
                opacity 0.2s ease-out;
    will-change: opacity, transform;
}

.init_top {
    opacity: 0;
    transform: translateY(-50%);
    transition: transform 0.2s ease-out,
                opacity 0.2s ease-out;
    will-change: opacity, transform;
}

.init_bottom {
    opacity: 0;
    transform: translateY(50%);
    transition: transform 0.2s ease-out,
                opacity 0.2s ease-out;
    will-change: opacity, transform;
}

.is_visible {
    opacity: 1;
    transform: translateX(0%);
}
